<template>
  <SectionWrap>
    <ErrorMessage title="There was an error loading this page!">
      <small>
        <pre>{{ error }}</pre>
      </small>
      <ButtonIcon :href="basePath" icon="warning">
        Click here to go back
      </ButtonIcon>
    </ErrorMessage>
  </SectionWrap>
</template>

<script>
import { ButtonIcon, SectionWrap } from '@unimelb/pattern-lib-vue';
import ErrorMessage from '~/components/ErrorMessage.vue';

export default {
  components: {
    ButtonIcon,
    SectionWrap,
    ErrorMessage,
  },
  props: {
    error: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
};
</script>

<style lang="postcss">
body > svg {
  position: absolute;
  width: 0;
  height: 0;
}
</style>
